<template>
  <div id="page">
    <Header />
    <Footer />
    <PageTitle :title="'Profile'" />
    <div class="page-title-clear"></div>
    <div class="page-content fixed-height-page">
      <div
        class="card card-style bg-profile-static"
        :style="{
          backgroundImage: `url(${bUrl})`,
        }"
        data-card-height="400"
      >
        <div class="card-top text-center">
          <h1 class="color-white text-center font-22 pt-3 mb-0"></h1>
          <h1 class="color-white font-40 font-900">John Doe</h1>
        </div>
        <div class="card-bottom text-center">
          <p class="color-white font-15 opacity-70 px-4"></p>
        </div>

        <div class="card-overlay bg-gradient"></div>
      </div>
      <div class="card card-style">
        <div class="content mt-3 mb-2">
          <h1 class="vcard-title font-24 font-700 text-capitalize">
            User Information
          </h1>
          <div class="vcard-field">
            <strong>Email</strong><a href="">home@domain.com</a
            ><i class="fa fa-home color-green1-dark"></i>
          </div>
          <div class="vcard-field border-0">
            <strong>Name</strong><a href="">personal@domain.com</a
            ><i class="fa fa-user color-red2-dark"></i>
          </div>
        </div>
      </div>
      <div class="">
        <a
          @click="signOut"
          class="
            close-menu
            btn btn-margins btn-full
            gradient-red
            font-13
            btn-l
            font-600
            mt-3
            rounded-sm
          "
          >Sign Out</a
        >
      </div>
      <div data-menu-load="menu-footer.html"></div>
    </div>
    <!-- Page content ends here-->
    <div @click="menuClose" class="menu-hider"></div>
  </div>
</template>

<script>
import Footer from "@/components/Footer";
import Header from "@/components/Header";
import PageTitle from "@/components/PageTitle";
import { init_template, menuClose } from "@/appkit.js";
export default {
  components: { Header, Footer, PageTitle },
  data() {
    return {
      event: {},
      bUrl: "https://sbticket.kreya.my.id/img/img-default-profile.png",
    };
  },
  mounted() {
    init_template();
  },
  methods: {
    menuClose,
    signOut() {
      this.$store
        .dispatch("auth/signOut")
        .then(() => {
          this.$router.push({ name: "Splash" });
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
};
</script>
