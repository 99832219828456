<template>
  <div class="page-title page-title-fixed">
    <h1>{{ title }}</h1>
    <a
      href="#"
      class="page-title-icon shadow-xl bg-theme color-theme"
      data-menu="menu-share"
      ><i class="fa fa-share-alt"></i
    ></a>
    <a
      href="#"
      class="page-title-icon shadow-xl bg-theme color-theme show-on-theme-light"
      data-toggle-theme=""
      ><i class="fa fa-moon"></i
    ></a>
    <a
      href="#"
      class="page-title-icon shadow-xl bg-theme color-theme show-on-theme-dark"
      data-toggle-theme=""
      ><i class="fa fa-lightbulb color-yellow-dark"></i
    ></a>
    <a
      href="#"
      class="page-title-icon shadow-xl bg-theme color-theme"
      data-menu="menu-main"
      ><i class="fa fa-bars"></i
    ></a>
  </div>
  <div class="page-title page-title-fixed">
    <h1>{{ title }}</h1>
    <a
      href="#"
      class="page-title-icon shadow-xl bg-theme color-theme"
      data-menu="menu-share"
      ><i class="fa fa-share-alt"></i
    ></a>
    <a
      href="#"
      class="page-title-icon shadow-xl bg-theme color-theme show-on-theme-light"
      data-toggle-theme=""
      ><i class="fa fa-moon"></i
    ></a>
    <a
      href="#"
      class="page-title-icon shadow-xl bg-theme color-theme show-on-theme-dark"
      data-toggle-theme=""
      ><i class="fa fa-lightbulb color-yellow-dark"></i
    ></a>
    <a
      href="#"
      class="page-title-icon shadow-xl bg-theme color-theme"
      data-menu="menu-main"
      ><i class="fa fa-bars"></i
    ></a>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
  },
};
</script>
